import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  storm: {
    name: 'Storm',
    league: 'U14B',
    players: [
      'hammiedavis',
    ],
  },
  blast: {
    name: 'Blast',
    league: 'U12B',
    players: [
      'noahdavis',
    ],
  },
  dfeeters: {
    name: 'Dfeeters',
    league: 'U10G',
    players: [
      'keelydavis',
    ],
  },
};

export const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    addTeam: (state, action) => {
      state[action.team.key] = action.team;
    },
  },
});

export const { addTeam } = teamSlice.actions;

export default teamSlice.reducer;
