import React from 'react';
import Draggable from 'react-draggable';
import { useDispatch } from 'react-redux';
import { updateLocation } from '../reducers/player-slice';

// https://github.com/react-grid-layout/react-draggable#draggable-usage
const Player = ({ player, count }) => {
  const dispatch = useDispatch();

  return (
    <Draggable
      handle=".handle"
      onStart={() => console.log(player.name, count)}
      defaultPosition={{ x: player.coordX, y: player.coordY }}
      onStop={(e, d) => {
        console.log(`(${d.x}, ${d.y})`);
        dispatch(updateLocation({ id: player.id, x: d.x, y: d.y }));
      }}
    >
      <div className="player">
        <code className="handle">{player.name}</code>
      </div>
    </Draggable>
  );
};

export default Player;
