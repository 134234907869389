import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentTeam, setMenuOpen } from '../reducers/app-slice';

const Header = () => {
  const dispatch = useDispatch();
  const { currentTeam } = useSelector((state) => state.app);
  const availableTeams = useSelector((state) => state.team);

  return (
    <header>
      <div className="current-team">
        <select value={currentTeam} onChange={(e) => dispatch(setCurrentTeam(e.target.value))}>
          {Object.keys(availableTeams).map((team) => (
            <option key={team} value={team}>{availableTeams[team].name}</option>
          ))}
        </select>
      </div>
      <div className="menu-button">
        <button className="styled" onClick={() => dispatch(setMenuOpen(true))}>menu</button>
      </div>
    </header>
  );
};

export default Header;
