import { configureStore } from '@reduxjs/toolkit';
import playerReducer from './player-slice';
import teamReducer from './team-slice';
import appReducer from './app-slice';
import { loadState } from '../utils/local-storage';

export default configureStore({
  reducer: {
    player: playerReducer,
    team: teamReducer,
    app: appReducer,
  },
  preloadedState: loadState(),
});
