/* eslint-disable import/prefer-default-export */
export const getPlayersForCurrentTeam = ({
  player, app,
}) => {
  console.log(player);
  return Object.keys(player).map((playerKey) => {
    if (player[playerKey].team !== app.currentTeam) {
      return false;
    }
    return {
      ...player[playerKey],
      id: playerKey,
    };
  }).filter((_) => _);
};

export const getTeamData = ({ app, team }) => ({
  team: app.currentTeam,
  teamDisplay: team[app.currentTeam].name,
});
