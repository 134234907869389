import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMenuOpen, setMenuType } from '../reducers/app-slice';
import { addPlayers } from '../reducers/player-slice';
import { getPlayersForCurrentTeam, getTeamData } from '../selectors';
import { parsePlayerState } from '../utils/process-bulk-players';

const TeamEdit = () => {
  const dispatch = useDispatch();
  const { team, teamDisplay } = useSelector(getTeamData);
  const players = useSelector(getPlayersForCurrentTeam);
  const [playerList, setPlayerList] = useState(parsePlayerState(players));

  return (
    <>
      <section>
        <div>
          {`for ${teamDisplay}:`}
        </div>
        <div>
          <textarea value={playerList} onChange={({ target }) => setPlayerList(target.value)} />
        </div>
        <div>
          <button
            onClick={() => {
              dispatch(addPlayers({ team, playerList }));
              dispatch(setMenuOpen(false));
            }}
          >
            save
          </button>
        </div>
      </section>
    </>
  );
};

const Main = () => {
  const dispatch = useDispatch();
  // const positions = ['hey'];

  return (
    <>
      {/* <section className="save-position">
        <div>
          <input type="text" placeholder="name of position" />
        </div>
        <div>
          <button onClick={() => console.log('you clicked me')}>save</button>
        </div>
      </section>
      <section className="load-position">
        <div>
          <select value="hey">
            {positions.map((position) => (
              <option value={position} key={position}>{position}</option>
            ))}
          </select>
        </div>
        <div>
          <button onClick={() => console.log('you clicked me')}>load</button>
        </div>
      </section> */}
      <section>
        <div>
          edit current team
        </div>
        <div>
          <button onClick={() => dispatch(setMenuType('team'))}>edit</button>
        </div>
      </section>
    </>
  );
};

const Menu = () => {
  const dispatch = useDispatch();
  const menuOpen = useSelector(({ app }) => app.menuOpen);
  const menuType = useSelector(({ app }) => app.menuType);

  const components = {
    main: (<Main />),
    team: (<TeamEdit />),
  };

  return menuOpen && (
    <div className="modal menu">
      <header>
        <div>
          <button onClick={() => dispatch(setMenuOpen(false))} className="styled">X</button>
        </div>
      </header>
      {components[menuType]}
    </div>
  );
};

export default Menu;
