import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentTeam: 'storm',
  menuOpen: false,
  menuType: 'main',
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setCurrentTeam: (state, action) => {
      state.currentTeam = action.payload;
    },
    setMenuOpen: (state, action) => {
      console.log('setting menu to:', action.payload);
      state.menuOpen = action.payload;
      state.menuType = 'main';
    },
    setMenuType: (state, action) => {
      state.menuType = action.payload;
    },
  },
});

export const { setCurrentTeam, setMenuOpen, setMenuType } = appSlice.actions;

export default appSlice.reducer;
