import { createSlice } from '@reduxjs/toolkit';
import { processBulkPlayers } from '../utils/process-bulk-players';

const initialState = {};

// https://react-redux.js.org/tutorials/quick-start
export const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    addPlayers: (state, action) => {
      const newState = {};
      const processedPlayers = processBulkPlayers(action.payload);
      const { team } = action.payload;
      Object.keys(processedPlayers).forEach((playerKey) => {
        if (state.hasOwnProperty(playerKey)) {
          newState[playerKey] = state[playerKey];
        } else {
          newState[playerKey] = processedPlayers[playerKey];
        }
      });
      Object.keys(state).forEach((playerKey) => {
        if (state[playerKey].team !== team) {
          newState[playerKey] = state[playerKey];
        }
      });
      return newState;
    },
    updateLocation: (state, action) => {
      const { id, x, y } = action.payload;
      state[id].coordX = x;
      state[id].coordY = y;
    },
  },
});

export const { addPlayers, updateLocation } = playerSlice.actions;

export default playerSlice.reducer;
