import React from 'react';
import { Provider } from 'react-redux';
import './App.css';
import Field from './components/field';
import store from './reducers';
import Header from './components/header';
import { saveState } from './utils/local-storage';
import Menu from './components/menu';

store.subscribe(() => {
  console.log(store.getState());
  saveState(store.getState());
});

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <Header />
        <Field />
      </div>
      <Menu />
    </Provider>
  );
}

export default App;
