const KEY = 'awesome-soccer-app-local-storage-key';

export const saveState = (state) => {
  localStorage[KEY] = JSON.stringify(state);
};

export const loadState = () => {
  if (localStorage[KEY]) {
    const loadedState = JSON.parse(localStorage[KEY]);
    if (loadedState) {
      return loadedState;
    }
  }
  return {};
};
