import React from 'react';
import { useSelector } from 'react-redux';
import Player from './player';
import fieldMarkings from '../field.svg';
import { getPlayersForCurrentTeam } from '../selectors';

const Field = () => {
  const players = useSelector(getPlayersForCurrentTeam);
  console.log(players);
  return (
    <section className="field">
      <img src={fieldMarkings} alt="field-markings" />
      <div className="bench">{' '}</div>
      <div className="players">
        {players.map((player, i) => (
          <Player key={player.id} player={player} count={i} />
        ))}
      </div>
    </section>
  );
};

export default Field;
