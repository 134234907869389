export const processBulkPlayers = ({ team, playerList }) => playerList.split('\n').filter((_) => _).reduce((acc, cur) => ({
  ...acc,
  [`${team}_${cur.toLowerCase().replace(/\s+/g, '')}`]: {
    name: cur,
    coordX: 0,
    coordY: 0,
    team,
  },
}), {});

export const parsePlayerState = (players) => players.map(({ name }) => name).join('\n');
